import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';



function MailChimp() {
    const formAction = "https://jasonnoxon.us13.list-manage.com/subscribe/post?u=7adb64ace6bf17ab89dcf4daa&amp;id=8bca0a7c9e&amp;f_id=00700ce3f0";
    const [validated, setValidated] = useState(false);

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }

        setValidated(true);
    };

    return (
        <Form
            action={formAction}
            method="post"
            target="_blank"
            noValidate
            validated={validated}
            onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="formBasicFirstName">
                <Form.Label>First Name</Form.Label>
                <Form.Control required type="text" name="FNAME" placeholder="Enter your first name" />
                <Form.Text className="text-muted">
                    I'll never share your email with anyone else.
                </Form.Text>
                <Form.Control.Feedback type="invalid">
                    Please enter a First Name! Come on!
                </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control required type="email" name="EMAIL" placeholder="Enter your email" />
                <Form.Control.Feedback type="invalid">
                    How am I going to send you an email without an Email Address?
                </Form.Control.Feedback>
            </Form.Group>
            <Button variant="primary" type="submit">
                Submit
            </Button>
        </Form>
    );
}

export default MailChimp;